


























































import { defineComponent, ref, Ref, computed, ComputedRef } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import { Entry, useForm } from '@/admin/form';
import { useUser } from '@/admin/user';
import { downloadCsv } from '@/admin/util';
import FcTableHeaderFilter from '@/components/FcTableHeaderFilter.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Entries',
  components: {
    FcTableHeaderFilter,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    formId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('forms'));

    const { getForm, getEntries } = useForm();
    const { userProperties } = useUser();

    const entryHeaders: Ref<DataTableHeader[]> = ref([]);
    const headers: ComputedRef<DataTableHeader[]> = computed(() => {
      const properties = [
        { text: '投稿日', value: 'entryDate.dateTimeSeconds' },
        ...entryHeaders.value,
        { text: 'ユーザーID', value: 'user.userId' },
        { text: 'メールアドレス', value: 'user.email' },
        ...userProperties.value.map(({ key, title }) => ({ text: title, value: `user.properties.${key}` })),
        { text: '会員番号', value: 'user.userNumber' },
        { text: '登録日', value: 'user.createDate' },
        { text: '退会日', value: 'user.deleteDate' },
      ];
      if (process.env.VUE_APP_GROUP_TYPE === 'plan')
        properties.push({ text: '加入中のプラン', value: 'user.subscriptionNames' });
      if (process.env.VUE_APP_GROUP_TYPE === 'season')
        properties.push({ text: '加入中のシーズン', value: 'user.seasonNames' });
      properties.push({ text: '備考', value: 'note' });
      return properties;
    });
    const displayHeaders: Ref<DataTableHeader[]> = ref([]);
    const entries: Ref<Entry[]> = ref([]);
    const isLoading = ref(true);

    const download = () => {
      downloadCsv(displayHeaders.value, entries.value, 'entries.csv');
    };

    Promise.all([getForm(props.formId), getEntries(props.formId)]).then(([getFormResult, getEntriesResult]) => {
      const form = getFormResult;
      entries.value = getEntriesResult;
      if (form) {
        entryHeaders.value = form.entryProperties.map((prop) => {
          return {
            text: prop.title,
            value: `entry.${prop.key}`,
          };
        });
      }
      isLoading.value = false;
    });

    return {
      pageTitle: 'フォーム',
      myRoleSettings,
      entries,
      headers,
      displayHeaders,
      isLoading,
      download,
    };
  },
});
